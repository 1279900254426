export const EVENT = Object.freeze({
  addCrediCard: 'Credit Card Added',
  addCrediCardFailed: 'Add Credit Card Failed',
  addNewAddress: 'New Address Added',
  brandDirectoryViewed: 'Brands Directory Viewed',
  categoryNavigationClicked: 'Category Navigation Clicked',
  cartProductMoveToWishlist: 'Cart Product Moved to Wishlist',
  cancelledProductAddToWishlist: 'Cancelled Product Add to Wishlist',
  cartViewed: 'Cart Viewed',
  checkoutStarted: 'Checkout Started',
  checkoutStepCompleted: 'Checkout Step Completed',
  confirmExtendClicked: 'Confirm Extend Clicked',
  cookieNoticeResponded: 'Cookie Notice Responded',
  cookieSettingUpdated: 'Cookie Setting Updated',
  couponApplied: 'Coupon Applied',
  couponFailed: 'Coupon Failed',
  extendPickupDayClicked: 'Extend Pick Up Day Clicked',
  findItemStoreListViewed: 'Find Item Store List Viewed',
  findItemStoreSelected: 'Find Item Store Selected',
  homeFeedImpression: 'Homefeed Impression',
  languageSelected: 'Language Selected',
  leftCart: 'Cart View Left',
  loggedIn: 'Logged In',
  lookbookViewed: 'Lookbook Viewed',
  loyaltyLandingPageViewed: 'Loyalty Landing Page Viewed',
  myLoyaltyPageViewed: 'My Loyalty Viewed',
  orderCompleted: 'Order Completed',
  orderDetailsViewed: 'Order Details Viewed',
  pdpaConsentResponded: 'PDPA Consent Responded',
  pomeloPickupInteracted: 'Pomelo Pickup List Interacted',
  popularSearchClicked: 'Search Bar Popular Search Clicked',
  productAdded: 'Product Added',
  productAddedToWishlist: 'Product Added to Wishlist',
  productCareInstructionViewed: 'PDP Care Instructions Viewed',
  productDetailViewed: 'PDP Details Viewed',
  productImpression: 'PDP Impression',
  productInternationalSizingViewed: 'PDP International Sizing Viewed',
  productListFiltered: 'Product List Filtered',
  productListSorted: 'Product List Sorted',
  productListViewed: 'Product List Viewed',
  productMaterialViewed: 'PDP Materials Viewed',
  productSizeGuideViewed: 'PDP Size Guide Viewed',
  productShopTheLookIconClicked: 'PDP Shop The Look Icon Clicked',
  productSoldOutNotifyMe: 'Product Sold Out Notify Me',
  productComingSoonNotifyMe: 'Coming Soon Notify Me',
  productSustainabilityViewed: 'PDP Sustainability Viewed',
  productRemoved: 'Product Removed',
  productViewed: 'Product Viewed',
  productImageGalleryViewed: 'Product Image Gallery Viewed',
  recentlyViewProduct: 'Recently Viewed',
  recentSearchClicked: 'Search Bar Recent Search Clicked',
  recommendedSizeAdded: 'Recommended Size Added To Bag',
  referralCodeShared: 'Referral code Shared',
  referralGuestLoginClicked: 'Referral Guest Login Clicked',
  referralGuestSignupClicked: 'Referral Guest Signup Clicked',
  referralGuestViewed: 'Referral Guest Viewed',
  referralInterimViewed: 'Referral Interim Viewed',
  referralLPViewed: 'Referral LP Viewed',
  referralPMLPerkSignupClicked: 'Referral PML Perk Signup Clicked',
  referralWelcomeCodeCopied: 'Referral Welcome Code Copied',
  referralWelcomeShopInAppClicked: 'Referral Welcome ShopInApp Clicked',
  referralWelcomeShopInWebClicked: 'Referral Welcome ShopInWeb Clicked',
  referralWelcomeStartEarningClicked: 'Referral Welcome StartEarning Clicked',
  referralWelcomeViewed: 'Referral Welcome Viewed',
  referralWelcomeWhoopsViewed: 'Referral Welcome Whoops Viewed',
  returnPomeloPickupViewed: 'Return Pomelo Pickup Viewed',
  searchIconClicked: 'Search Box Clicked',
  searchedProducts: 'Products Searched',
  selectCreditCard: 'Credit Card Selected',
  selectPaymentMethod: 'Payment Method Selected',
  selectPickupLocations: 'Pomelo Pickup Location Selected',
  selectShippingAddress: 'Shipping Address Selected',
  selectShippingMethod: 'Shipping Method Selected',
  shipmentDetailViewed: 'Shipment Detail Viewed',
  signedUp: 'Complete Registration',
  signInViewed: 'Sign In Viewed',
  skinnyBannerClicked: 'Skinny Banner Clicked',
  sustainabilityLookbookView: 'PDP Sustainability Lookbook Viewed',
  swatchOverlayViewed: 'Swatch Overlay Viewed',
  updateCommunicationPreferences: 'Communication Preferences Updated',
  viewListOfPickupLocations: 'Pomelo Pickup List Viewed',
  voucherCheckoutClicked: 'Voucher Checkout Clicked',
  voucherClicked: 'PDP Voucher Clicked',
  voucherCopied: 'PDP Voucher Copied',
  voucherViewed: 'PDP Voucher Viewed',
  wishlistProductAddedToCart: 'Wishlist Product Added to Cart',
  wishlistViewed: 'Wishlist Viewed',
})

export const SEGMENT_AUTH_EMAIL = 'email'
export const SEGMENT_AUTH_PASSWORD = 'password'
export const SEGMENT_AUTH_FACEBOOK = 'facebook'
export const SEGMENT_AUTH_FACEBOOK_DOTCOM = 'facebook.com'
export const SEGMENT_AUTH_GOOGLE = 'google'
export const SEGMENT_AUTH_GOOGLE_DOTCOM = 'google.com'
export const SEGMENT_AUTH_PHONE = 'phone'

export const SEGMENT_STORAGE_KEY_SEARCH_ID = 'segment_search_id'
export const SEGMENT_STORAGE_KEY_SEARCH_IDX = 'segment_search_idx'
export const SEGMENT_STORAGE_KEY_SSR = 'segment_ssr_tracked'
export const SEGMENT_STORAGE_KEY_LAST_URL = 'segment_last_url'
export const SEGMENT_STORAGE_KEY_IS_REFRESHED = 'segment_is_refreshed'
export const SEGMENT_STORAGE_KEY_PRODUCT = Object.freeze({
  colorSwatch: 'segment_product_color_swatch',
  type: 'segment_product_ref_type',
  id: 'segment_product_ref_id',
  position: 'segment_product_click_position',
  subPos: 'segment_product_click_sub_position',
  stack: 'segment_product_ref_stack',
  ssr_uuid: 'ssr_id',
  category_uuid: 'category_id',
  query_id: SEGMENT_STORAGE_KEY_SEARCH_ID,
  query_idx: SEGMENT_STORAGE_KEY_SEARCH_IDX,
  id_lookbook: 'segment_lookbook_id',
  lookbook_name: 'segment_lookbook_name',
})
export const SEGMENT_STORAGE_KEY_CATEGORY = Object.freeze({
  type: 'segment_category_ref_type',
  id: 'segment_category_ref_id',
  position: 'segment_category_click_position',
  subPos: 'segment_category_click_sub_position',
})
export const SEGMENT_NO_CLICK_POSITION_REF_TYPES = [
  'external',
  'landing_page',
  'bag',
  'order_review',
  'order_detail',
  'return_detail',
  'lookbook_shop_look',
]
export const SEGMENT_STORAGE_KEY_PROMPT_LOCATION =
  'segment_auth_prompt_location'
export const BRANCH_CPID = 'branch_cpid'
export const SEGMENT_STORAGE_KEY_NAVIGATION = Object.freeze({
  wishlist: {
    type: 'segment_wishlist_ref_type',
    id: 'segment_wishlist_ref_id',
    position: 'segment_wishlist_click_position',
  },
})

export const SESSION_STORAGE_SIGN_UP = 'auth_just_sign_up'
export const SEGMENT_RECOMMENDATION_QUERY_ID = 'segment_recommendation_query_id'
export const SEGMENT_JUST_FOR_YOU_QUERY_ID = 'segment_just_for_you_query_id'
export const SEGMENT_CATEGORY_QUERY_ID = 'segment_category_query_id'
