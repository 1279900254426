import { getAssetPath } from 'lib/utils/common/commonUtils'

const ICON_SIZE = {
  default: 24,
  small: 16,
  smallMedium: 18,
  medium: 24,
  large: 32,
  extraLarge: 48,
  jumbo: 80,
}

const ICON_PREFIX = `${getAssetPath()}/static/img/icons/`

const ICONS = {
  cookies: `${ICON_PREFIX}alert/cookie-privacy.svg`,
  filters: `${ICON_PREFIX}category/filters.svg`,
  filtersDisabled: `${ICON_PREFIX}category/filters-disabled.svg`,
  gridActive: `${ICON_PREFIX}category/grid-active.svg`,
  listActive: `${ICON_PREFIX}category/list-active.svg`,
  listDisabled: `${ICON_PREFIX}category/list-disabled.svg`,
  eWallet: `${ICON_PREFIX}checkout/gopay/e-wallet.svg`,
  webAddress: `${ICON_PREFIX}checkout/web-address.svg`,
  webCod: `${ICON_PREFIX}checkout/web-cod.svg`,
  webPayment: `${ICON_PREFIX}checkout/web-payment.svg`,
  webShipping: `${ICON_PREFIX}checkout/web-shipping.svg`,
  webSummary: `${ICON_PREFIX}checkout/web-summary.svg`,
  webThankYou: `${ICON_PREFIX}checkout/web-thank-you.svg`,
  webTransfer: `${ICON_PREFIX}checkout/web-transfer.svg`,
  webCreditCard: `${ICON_PREFIX}checkout/web-credit-card.svg`,
  webError: `${ICON_PREFIX}checkout/web-error.svg`,
  wrong: `${ICON_PREFIX}checkout/wrong.svg`,
  webWaitingTransfer: `${ICON_PREFIX}checkout/web-waiting-transfer.svg`,
  payAtPickup: `${ICON_PREFIX}checkout/pay-at-pickup.svg`,
  pickUpLocation: `${ICON_PREFIX}checkout/pick-up-location.svg`,
  phoneCall: `${ICON_PREFIX}checkout/phone-call.svg`,
  payCC: `${ICON_PREFIX}checkout/pay-cc.svg`,
  payQR: `${ICON_PREFIX}checkout/pay-qr.svg`,
  receiveSms: `${ICON_PREFIX}checkout/receive-sms.svg`,
  getPackage: `${ICON_PREFIX}checkout/get-package.svg`,
  webCurrentLocation: `${ICON_PREFIX}checkout/web-current-location.svg`,
  webCurrentLocationDisabled: `${ICON_PREFIX}checkout/web-current-location-disabled.svg`,
  visa: `${ICON_PREFIX}checkout/visa.svg`,
  mastercard: `${ICON_PREFIX}checkout/master-card.svg`,
  amex: `${ICON_PREFIX}checkout/amex.svg`,
  cup: `${ICON_PREFIX}checkout/union-pay.svg`,
  jcb: `${ICON_PREFIX}checkout/jcb.svg`,
  ccv: `${ICON_PREFIX}checkout/ccv.svg`,
  cid: `${ICON_PREFIX}checkout/cid.svg`,
  bni: `${ICON_PREFIX}checkout/bni.svg`,
  bca: `${ICON_PREFIX}checkout/bca.svg`,
  mandiri: `${ICON_PREFIX}checkout/mandiri.svg`,
  bankTransfer: `${ICON_PREFIX}checkout/bank-transfer.svg`,
  promptPay: `${ICON_PREFIX}checkout/prompt-pay.svg`,
  promptPayLogo: `${ICON_PREFIX}checkout/prompt-pay-logo.svg`,
  cashOnDelivery: `${ICON_PREFIX}checkout/cash-on-delivery.svg`,
  checkoutDelivery: `${ICON_PREFIX}checkout/delivery.svg`,
  creditCard: `${ICON_PREFIX}checkout/creditcard.svg`,
  gopay: `${ICON_PREFIX}checkout/gopay/gopay-icon.svg`,
  payAtPick: `${ICON_PREFIX}checkout/pay-at-pick.svg`,
  checkoutCOD: `${ICON_PREFIX}checkout/cod.svg`,
  mobileBanking: `${ICON_PREFIX}checkout/mobile-banking.svg`,
  guaranteeBadge: `${ICON_PREFIX}checkout/guarantee-badge.svg`,
  visaBadge: `${ICON_PREFIX}checkout/visa-badge.svg`,
  masterCardBadge: `${ICON_PREFIX}checkout/master-card-badge.svg`,
  creditCardLock: `${ICON_PREFIX}checkout/credit-card-lock.svg`,
  voucherEmpty: `${ICON_PREFIX}checkout/voucher.svg`,
  voucherPurple: `${ICON_PREFIX}checkout/voucher-purple.svg`,
  basicInfo: `${ICON_PREFIX}faq/basic-info.svg`,
  contact: `${ICON_PREFIX}faq/contact.svg`,
  discount: `${ICON_PREFIX}faq/discount.svg`,
  faqLoyalty: `${ICON_PREFIX}faq/loyalty.svg`,
  order: `${ICON_PREFIX}faq/order.svg`,
  payment: `${ICON_PREFIX}faq/payment.svg`,
  privacy: `${ICON_PREFIX}faq/privacy.svg`,
  purchasing: `${ICON_PREFIX}faq/purchasing.svg`,
  return: `${ICON_PREFIX}faq/return.svg`,
  shipping: `${ICON_PREFIX}faq/shipping.svg`,
  sizing: `${ICON_PREFIX}faq/sizing.svg`,
  measurementTape: `${ICON_PREFIX}product/measurement-tape.svg`,
  setting: `${ICON_PREFIX}faq/setting.svg`,
  store: `${ICON_PREFIX}faq/store.svg`,
  tc: `${ICON_PREFIX}faq/t&c.svg`,
  facebook: `${ICON_PREFIX}home-feed/facebook.svg`,
  instagram: `${ICON_PREFIX}home-feed/instagram.svg`,
  line: `${ICON_PREFIX}home-feed/line.svg`,
  messenger: `${ICON_PREFIX}home-feed/messenger.svg`,
  whatsapp: `${ICON_PREFIX}home-feed/whatsapp.svg`,
  youtube: `${ICON_PREFIX}home-feed/youtube.svg`,
  step1: `${ICON_PREFIX}in-store/steps/1.svg`,
  step2: `${ICON_PREFIX}in-store/steps/2.svg`,
  step3: `${ICON_PREFIX}in-store/steps/3.svg`,
  bag: `${ICON_PREFIX}in-store/bag.svg`,
  fittingRoom: `${ICON_PREFIX}in-store/fitting-room.svg`,
  outOfLocation: `${ICON_PREFIX}in-store/out-of-location.svg`,
  apple: `${ICON_PREFIX}login-signup/apple.svg`,
  email: `${ICON_PREFIX}login-signup/email.svg`,
  facebookLogin: `${ICON_PREFIX}login-signup/facebook.svg`,
  facebookV2: `${ICON_PREFIX}login-signup/facebook-v2.svg`,
  google: `${ICON_PREFIX}login-signup/google.svg`,
  googleV2: `${ICON_PREFIX}login-signup/google-v2.svg`,
  letter: `${ICON_PREFIX}login-signup/letter.svg`,
  hide: `${ICON_PREFIX}login-signup/hide.svg`,
  newArrivals: `${ICON_PREFIX}login-signup/new-arrivals.svg`,
  orderStatus: `${ICON_PREFIX}login-signup/order-status.svg`,
  phone: `${ICON_PREFIX}login-signup/phone.svg`,
  show: `${ICON_PREFIX}login-signup/show.svg`,
  tag: `${ICON_PREFIX}login-signup/tag.svg`,
  check: `${ICON_PREFIX}login-signup/check.svg`,
  facebookAuthMerge: `${ICON_PREFIX}login-signup/facebook-auth-merge.svg`,
  googleAuthMerge: `${ICON_PREFIX}login-signup/google-auth-merge.svg`,
  loyalty: `${ICON_PREFIX}loyalty/loyalty.svg`,
  loyaltyWhite: `${ICON_PREFIX}loyalty/loyalty-white.svg`,
  addressBook: `${ICON_PREFIX}me/address-book.svg`,
  avatar: `${ICON_PREFIX}me/avatar.svg`,
  creditDebitCard: `${ICON_PREFIX}me/credit-debit-card.svg`,
  debitCard: `${ICON_PREFIX}me/debit-card.svg`,
  edit: `${ICON_PREFIX}me/edit.svg`,
  faq: `${ICON_PREFIX}me/faq.svg`,
  faq1: `${ICON_PREFIX}me/faq-1.svg`,
  inStoreMode: `${ICON_PREFIX}me/in-store-mode.svg`,
  inbox: `${ICON_PREFIX}me/inbox.svg`,
  language: `${ICON_PREFIX}me/language.svg`,
  logout: `${ICON_PREFIX}me/logout.svg`,
  me: `${ICON_PREFIX}me/me.svg`,
  more: `${ICON_PREFIX}me/more.svg`,
  myScanId: `${ICON_PREFIX}me/my-scan-id.svg`,
  notiEmail: `${ICON_PREFIX}me/noti-email.svg`,
  notiPhone: `${ICON_PREFIX}me/noti-phone.svg`,
  notiWeb: `${ICON_PREFIX}me/noti-web.svg`,
  notification: `${ICON_PREFIX}me/notification.svg`,
  notificationGhost: `${ICON_PREFIX}me/notification-1.svg`,
  meOrder: `${ICON_PREFIX}me/order.svg`,
  orders: `${ICON_PREFIX}me/orders.svg`,
  mePhone: `${ICON_PREFIX}me/phone.svg`,
  pomeloCredit: `${ICON_PREFIX}me/pomelo-credit.svg`,
  pomeloId: `${ICON_PREFIX}me/pomelo-id.svg`,
  purpose: `${ICON_PREFIX}me/purpose.svg`,
  meReturn: `${ICON_PREFIX}me/return.svg`,
  meStore: `${ICON_PREFIX}me/store.svg`,
  storeCredit: `${ICON_PREFIX}me/store-credit.svg`,
  storeCreditGhost: `${ICON_PREFIX}me/store-credit-1.svg`,
  support: `${ICON_PREFIX}me/support.svg`,
  webAddressBook: `${ICON_PREFIX}me/web-address-book.svg`,
  webReturn: `${ICON_PREFIX}me/web-return.svg`,
  wishlist: `${ICON_PREFIX}me/wishlist.svg`,
  wishlistGhost: `${ICON_PREFIX}me/wishlist-1.svg`,
  inStore: `${ICON_PREFIX}order-refund/in-store.svg`,
  ordered: `${ICON_PREFIX}order-refund/ordered.svg`,
  cashMachine: `${ICON_PREFIX}order-refund/cash-machine.svg`,
  orderPhone: `${ICON_PREFIX}order-refund/phone.svg`,
  paymentPending: `${ICON_PREFIX}order-refund/payment-pending.svg`,
  pickUp: `${ICON_PREFIX}order-refund/pick-up.svg`,
  pickUpComplete: `${ICON_PREFIX}order-refund/pick-up-complete.svg`,
  pomeloPickUp: `${ICON_PREFIX}order-refund/pomelopickup.svg`,
  processing: `${ICON_PREFIX}order-refund/processing.svg`,
  orderExpired: `${ICON_PREFIX}order-refund/order-expired.svg`,
  refund: `${ICON_PREFIX}order-refund/refund.svg`,
  shipped: `${ICON_PREFIX}order-refund/shipped.svg`,
  externalLink: `${ICON_PREFIX}order-refund/external-link.svg`,
  orderTitle: `${ICON_PREFIX}order-refund/order-title.svg`,
  product3d: `${ICON_PREFIX}product/3d.svg`,
  productBag: `${ICON_PREFIX}product/bag.svg`,
  chat: `${ICON_PREFIX}product/chat.svg`,
  chatPurple: `${ICON_PREFIX}product/chat-purple.svg`,
  pomeloPickup1: `${ICON_PREFIX}product/pomelo-pickup-1.svg`,
  pomeloPickup2: `${ICON_PREFIX}product/pomelo-pickup-2.svg`,
  pomeloPickup3: `${ICON_PREFIX}product/pomelo-pickup-3.svg`,
  phoneScanQRCode: `${ICON_PREFIX}product/phone-scan-qr-code.svg`,
  pickupLocation: `${ICON_PREFIX}pick-up/pick-up-location.svg`,
  pickupNotification: `${ICON_PREFIX}pick-up/pick-up-notification.svg`,
  pickUpMarker: `${ICON_PREFIX}pick-up/pick-up-marker.svg`,
  pickupBag: `${ICON_PREFIX}pick-up/pick-up-bag.svg`,
  trainStation: `${ICON_PREFIX}pick-up/train-station.svg`,
  productShare: `${ICON_PREFIX}product/share.svg`,
  shopTheLook: `${ICON_PREFIX}product/shop-the-look.svg`,
  voucher: `${ICON_PREFIX}product/voucher-pdp.svg`,
  waitListed: `${ICON_PREFIX}product/waitlisted.svg`,
  productWishlist: `${ICON_PREFIX}product/wishlist.svg`,
  productWishlistFilled: `${ICON_PREFIX}product/wishlist-filled.svg`,
  zoom: `${ICON_PREFIX}product/zoom.svg`,
  circleOutlined: `${ICON_PREFIX}product/circle-outlined.svg`,
  circleFilled: `${ICON_PREFIX}product/circle-filled.svg`,
  noReturn: `${ICON_PREFIX}product/no-return.svg`,
  promoCodeVerified: `${ICON_PREFIX}promo-code/verified.svg`,
  dislike: `${ICON_PREFIX}quiz/dislike.svg`,
  icon: `${ICON_PREFIX}quiz/icon.svg`,
  like: `${ICON_PREFIX}quiz/like.svg`,
  emptySearch: `${ICON_PREFIX}shopping-bag/empty-search.svg`,
  emptyShoppingBag: `${ICON_PREFIX}shopping-bag/empty-shoppingbag.svg`,
  freeGift: `${ICON_PREFIX}shopping-bag/free-gift.svg`,
  promoError: `${ICON_PREFIX}shopping-bag/promo-error.svg`,
  checkBoxActive: `${ICON_PREFIX}system/checkbox/active.svg`,
  checkBoxInactive: `${ICON_PREFIX}system/checkbox/inactive.svg`,
  radioActive: `${ICON_PREFIX}system/radio/active.svg`,
  radioInactive: `${ICON_PREFIX}system/radio/inactive.svg`,
  add: `${ICON_PREFIX}system/add.svg`,
  arrowHeadGhost: `${ICON_PREFIX}system/arrow-head-ghost.svg`,
  arrowHeadRight: `${ICON_PREFIX}system/arrow-head-right.svg`,
  arrowHeadRightRed: `${ICON_PREFIX}system/arrow-head-right-red.svg`,
  arrowHeadRightPurple: `${ICON_PREFIX}system/arrow-head-right-purple.svg`,
  arrowHeadLeft: `${ICON_PREFIX}system/arrow-head-left.svg`,
  close: `${ICON_PREFIX}system/close.svg`,
  closeWhite: `${ICON_PREFIX}system/closewhite.svg`,
  clock: `${ICON_PREFIX}system/clock.svg`,
  clockPurple: `${ICON_PREFIX}system/clock-purple.svg`,
  delete: `${ICON_PREFIX}system/delete.svg`,
  disable: `${ICON_PREFIX}system/disable.svg`,
  dropdown: `${ICON_PREFIX}system/dropdown.svg`,
  dropdownSmall: `${ICON_PREFIX}system/dropdown-small.svg`,
  dropdownSmallGhost: `${ICON_PREFIX}system/dropdown-small-ghost.svg`,
  dropupSmall: `${ICON_PREFIX}system/dropup-small.svg`,
  hamburger: `${ICON_PREFIX}system/hamburger.svg`,
  info: `${ICON_PREFIX}system/info.svg`,
  grayInfo: `${ICON_PREFIX}system/info-gray.svg`,
  emptyCredit: `${ICON_PREFIX}system/pml-logo-empty-credit.svg`,
  minus: `${ICON_PREFIX}system/minus.svg`,
  plus: `${ICON_PREFIX}system/plus.svg`,
  plusWhite: `${ICON_PREFIX}system/plus-white.svg`,
  remove: `${ICON_PREFIX}system/remove.svg`,
  share: `${ICON_PREFIX}system/share.svg`,
  shareLink: `${ICON_PREFIX}system/share-link.svg`,
  similarProduct: `${ICON_PREFIX}system/similar-product.svg`,
  verified: `${ICON_PREFIX}system/verified.svg`,
  pmlLogo: `${ICON_PREFIX}system/pml-logo-prod.svg`,
  pmlLogoStaging: `${ICON_PREFIX}system/pml-logo-staging-2.svg`,
  pmlLogoPreProd: `${ICON_PREFIX}system/pml-logo-pre-prod-2.svg`,
  search: `${ICON_PREFIX}system/search.svg`,
  pmlSmallLogo: `${ICON_PREFIX}system/pml-logo-small.svg`,
  emptyReturns: `${ICON_PREFIX}system/empty-returns.svg`,
  error: `${ICON_PREFIX}system/error.svg`,
  starMediumPurple: `${ICON_PREFIX}system/star-medium-purple.svg`,
  starGray80: `${ICON_PREFIX}system/star-gray80.svg`,
  starGold: `${ICON_PREFIX}system/star-gold.svg`,
  starHalf: `${ICON_PREFIX}system/star-half.svg`,
  cod: `${ICON_PREFIX}usp/cod.svg`,
  deliveryUSP: `${ICON_PREFIX}usp/delivery.svg`,
  pomeloPickupUSP: `${ICON_PREFIX}usp/pomelo-pickup.svg`,
  pomeloPickupUSPGhost: `${ICON_PREFIX}usp/pomelo-pickup-ghost.svg`,
  returnsUSP: `${ICON_PREFIX}usp/returns.svg`,
  codGhost: `${ICON_PREFIX}usp/cod-ghost.svg`,
  deliveryGhost: `${ICON_PREFIX}usp/delivery-ghost.svg`,
  uspNewArrivals: `${ICON_PREFIX}usp/new-arrivals.svg`,
  uspNewArrivalsGhost: `${ICON_PREFIX}usp/new-arrivals-ghost.svg`,
  returnsGhost: `${ICON_PREFIX}usp/returns-ghost.svg`,
  creditCardGhost: `${ICON_PREFIX}usp/credit-card-ghost.svg`,
  pickupPhone: `${ICON_PREFIX}pick-up/pick-up-phone-vibrate.svg`,
  pickupTime: `${ICON_PREFIX}pick-up/time.svg`,
  pickupTryon: `${ICON_PREFIX}pick-up/tryon.svg`,
  pickupTTBExchnage: `${ICON_PREFIX}pick-up/ttb-exchange.svg`,
  pickupLocationAccent: `${ICON_PREFIX}pick-up/pick-up-location-accent.svg`,
  pickupLocationUnavailable: `${ICON_PREFIX}pick-up/pick-up-location-unavailable.svg`,
  th: `${ICON_PREFIX}countries/th-flag.svg`,
  sg: `${ICON_PREFIX}countries/sg-flag.svg`,
  id: `${ICON_PREFIX}countries/id-flag.svg`,
  us: `${ICON_PREFIX}countries/usa.svg`,
  au: `${ICON_PREFIX}countries/au-flag.svg`,
  my: `${ICON_PREFIX}countries/my-flag.svg`,
  hk: `${ICON_PREFIX}countries/hk-flag.svg`,
  mo: `${ICON_PREFIX}countries/mo-flag.svg`,
  ph: `${ICON_PREFIX}countries/ph-flag.svg`,
  kh: `${ICON_PREFIX}countries/kh-flag.svg`,
  global: `${ICON_PREFIX}countries/country-global.svg`,
  success: `${ICON_PREFIX}system/success.svg`,
  pinStore: `${ICON_PREFIX}system/pin-store.svg`,
  play: `${ICON_PREFIX}system/play.svg`,
  arrowDown: `${ICON_PREFIX}system/arrow-down.svg`,
  processRequestOnGrab: `${ICON_PREFIX}order-refund/process-request-on-grab.svg`,
  tryOnCompleted: `${ICON_PREFIX}checkout/try-on-completed.svg`,
  returnRequested: `${ICON_PREFIX}system/return-requested.svg`,
  failedDelivery: `${ICON_PREFIX}order-refund/failed-delivery.svg`,
  paymentReversed: `${ICON_PREFIX}order-refund/payment-reversed.svg`,
  checkIcon: `${ICON_PREFIX}referral/check-icon.svg`,
  inviteIcon: `${ICON_PREFIX}referral/invite-icon.svg`,
  shoppingIcon: `${ICON_PREFIX}referral/shopping-icon.svg`,
  rewardIcon: `${ICON_PREFIX}referral/reward-icon.svg`,
  referralNo1Icon: `${ICON_PREFIX}referral/referral-no1-icon.svg`,
  referralNo2Icon: `${ICON_PREFIX}referral/referral-no2-icon.svg`,
  referralNo3Icon: `${ICON_PREFIX}referral/referral-no3-icon.svg`,
  gift: `${ICON_PREFIX}referral/gift.svg`,
  referralInprogessEmpty: `${ICON_PREFIX}referral/referral-inprogress-empty.svg`,
  referralSuccessfulEmpty: `${ICON_PREFIX}referral/referral-successful-empty.svg`,
  referralIncompleteEmpty: `${ICON_PREFIX}referral/referral-incomplete-empty.svg`,
  preferredMaterials: `${ICON_PREFIX}product/preferred-materials.svg`,
  preferredProcesses: `${ICON_PREFIX}product/preferred-processes.svg`,
  preLoved: `${ICON_PREFIX}product/pre-loved.svg`,
  community: `${ICON_PREFIX}product/community.svg`,
  ecoLifestyle: `${ICON_PREFIX}product/eco-lifestyle.svg`,
  reviewStar: `${ICON_PREFIX}review/review-star.svg`,
  reviewCashback: `${ICON_PREFIX}review/review-cashback.svg`,
}

export default ICONS

export { ICON_SIZE }
