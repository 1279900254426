import { request } from './index'
import { HTTP_METHODS } from './const'

class Order {
  static SOURCE_ITEM = 'item'

  static RETURNABLE_ORDER_LIST = 'RETURNABLE_ORDER_LIST'

  static handleFetch(options = {}) {
    switch (options.source) {
      case Order.RETURNABLE_ORDER_LIST:
        return Order.getAllReturnableOrders()
      case Order.SOURCE_ITEM:
        return Order.getItem(options)
      // TODO: Stop using default and use an object reference. (Currently very hard to trace)
      default:
        return Order.getList(options)
    }
  }

  static getAllReturnableOrders() {
    return request({
      url: `v6/returns/orders`,
      method: HTTP_METHODS.get,
    })
  }

  static getList() {
    return request({
      url: `v7/orders`,
      method: HTTP_METHODS.get,
    })
  }

  static getItem(options) {
    return request({
      url: `v7/orders/${options.orderId}`,
      method: HTTP_METHODS.get,
    })
  }

  static postOrder(options) {
    return request({
      url: `orders/checkout`,
      body: options.body,
      method: HTTP_METHODS.post,
    })
  }

  static retryPayment(options) {
    return request({
      url: `orders/orderpayment`,
      body: options.body,
      method: HTTP_METHODS.post,
    })
  }

  static updateInvoiceAddress(id, options) {
    return request({
      url: `v6/invoices/${id}/address`,
      body: options,
      method: HTTP_METHODS.patch,
    })
  }

  static getInvoice(id) {
    return request({
      url: `v6/invoices/${id}/download`,
      method: HTTP_METHODS.get,
      responseType: 'blob',
    })
  }

  static extendPickupDate(options) {
    return request({
      url: `v6/orders/${options.orderId}/extend`,
      method: HTTP_METHODS.post,
    })
  }

  static getOrderRefundSummary(options) {
    return request({
      method: HTTP_METHODS.post,
      url: `v6/orders/${options.id_order}/refund-summary`,
      body: {
        refund_type: options.refund_type,
        products: options.products,
      },
    })
  }
}

export default Order
