import ICONS from 'components/common/icon/const'

// cash delivery usp
const dataCash = Object.freeze({
  icon: ICONS.cod,
  title: {
    key: 'TOP_USP_CASH_ON_TITLE',
    default: 'Cash On Delivery',
  },
  subTitle: {
    key: 'TOP_USP_CASH_ON_SUBTITLE',
    default:
      'Pay for your order in person! Simply hand your cash to the delivery agent at your doorstep and receive your package. Great for your closet, good for your wallet.',
  },
})

// free delivery usp
const dataDelivery = Object.freeze({
  icon: ICONS.deliveryUSP,
  title: {
    key: 'TOP_USP_FREE_DELIVERY_TITLE',
    default: 'Free Delivery On Orders Up To $49 USD',
  },
  subTitle: {
    key: 'TOP_USP_FREE_DELIVERY_SUBTITLE',
    default:
      'Get your order in just days! Delivery is free for orders up to $49 USD.',
  },
})

// pick up usp
const dataPickup = Object.freeze({
  icon: ICONS.pomeloPickupUSP,
  title: {
    key: 'TOP_USP_PICK_UP_TITLE',
    default: 'Try first pay later',
  },
  subTitle: {
    key: 'TOP_USP_PICK_UP_SUBTITLE',
    default: 'Try first, pay only for the items you love!',
  },
  link: {
    href: '/ttb-home',
    as: '/ttb-home',
    keyText: 'TOP_USP_PICK_UP_LEARN_MORE',
    defaultText: 'Learn More',
  },
})

// weekly new arrivals usp
const dataNew = Object.freeze({
  icon: ICONS.uspNewArrivals,
  title: {
    key: 'TOP_USP_WEEKLY_ARRIVALS_TITLE',
    default: 'Weekly new arrivals',
  },
})

// days 365 return usp
const dataReturn = Object.freeze({
  icon: ICONS.returnsUSP,
  title: {
    key: 'TOP_USP_365_DAYS_TITLE',
    default: '365 Day Returns',
  },
  subTitle: {
    key: 'TOP_USP_365_DAYS_SUBTITLE',
    default:
      'Shop online risk-free with ultra easy 365 day returns on all orders.',
  },
  link: {
    href: '/info/returns',
    as: '/info/returns',
    keyText: 'TOP_USP_365_DAYS_LEARN_MORE',
    defaultText: 'Learn More',
  },
})

const getDataLoyalty = (usp) => ({
  icon: ICONS.loyalty,
  title: {
    key: usp.title.key,
    default: usp.title.val,
  },
  subTitle: {
    key: usp.description.key,
    default: usp.description.val,
  },
  link: {
    href: '/loyalty-program',
    as: '/loyalty-program',
    keyText: 'HEADER_USP_LOYALTY_CTA',
    defaultText: 'JOIN POMELO PERKS NOW',
  },
})

export {
  dataCash,
  dataDelivery,
  dataPickup,
  dataNew,
  dataReturn,
  getDataLoyalty,
}
