import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { withI18next } from 'lib/i18n/withI18next'
import { hasLocizeTranslation } from 'lib/utils/locize'
import ResponsiveImage from 'components/images/responsive-image'
import env from 'config/env'
import Typo from 'constants/typography'
import Badge3D from 'components/pdp/carousel-images/badge-3d/Badge3D'
import ProductItemHoverInteractions from './hover-interactions'

function getPremiumTags({
  displayPremiumTags,
  isBackInStock,
  isLowStock,
  isSoldOut,
  stockQuantity,
  isComingSoon,
  comingSoonText,
  t,
}) {
  if (!displayPremiumTags) {
    return null
  }

  if (isComingSoon) {
    return comingSoonText
  }

  if (isSoldOut) {
    return hasLocizeTranslation(
      t,
      'SOLD_OUT_ITEM',
      'Sold Out - Notify Me or Find Item at Store',
    )
  }

  if (isLowStock) {
    return hasLocizeTranslation(t, 'ONLY_ITEM_LEFT', 'Only %s left').replace(
      /%s/g,
      stockQuantity,
    )
  }

  if (isBackInStock) {
    return t('Back In Stock')
  }

  return null
}

function getImgPath(src) {
  let originSrc = src
  if (src?.charAt && src.charAt(0) === '/') {
    originSrc = src.substr(1, src.length - 1)
  }

  return `${env.IMG_HOST}${originSrc}`
}

const ProductItemFigure = ({
  className,
  coverSrc,
  cy,
  discountBadge,
  displayHoverInteractions,
  displayPremiumTags,
  goToSimilarProducts,
  hoverSrc,
  is3dImageAvailable,
  isBackInStock,
  isBeautyProduct,
  isListView,
  isLowStock,
  isSoldOut,
  isComingSoon,
  comingSoonText,
  name,
  onClick,
  options,
  productId,
  productUrl,
  refType,
  stockQuantity,
  t,
}) => {
  const imgProps = {
    alt: name,
    bigDesktop: '18vw',
    desktop: '18vw',
    imgRatio: 1.33,
    laptop: '18vw',
    phone: '45vw',
    tablet: '18vw',
  }
  const premiumTags = getPremiumTags({
    displayPremiumTags,
    isBackInStock,
    isLowStock,
    isSoldOut,
    stockQuantity,
    isComingSoon,
    comingSoonText,
    t,
  })

  const hasDisplayHoverInteractionsOrPremiumTags =
    displayHoverInteractions || !!premiumTags || is3dImageAvailable
  return (
    <a
      href={productUrl}
      className={clsx('product__link', { 'left-block': isListView })}
      onClick={onClick}
      data-cy={cy}
    >
      <figure className={clsx('product-image__fig', className || '')}>
        {!!coverSrc && (
          <div className="product-image__cover">
            <ResponsiveImage {...imgProps} src={getImgPath(coverSrc)} />
          </div>
        )}
        {!!hoverSrc && (
          <div className="product-image__hover">
            <ResponsiveImage {...imgProps} src={getImgPath(hoverSrc)} />
          </div>
        )}
        {!!discountBadge && (
          <div
            className="reduction-label"
            data-cy={cy ? `${cy}__reduction` : undefined}
          >
            <span className={Typo.price2}>{discountBadge}</span>
          </div>
        )}

        {!!hasDisplayHoverInteractionsOrPremiumTags && (
          <div className="product-image__fig__label">
            {displayHoverInteractions && (
              <ProductItemHoverInteractions
                goToSimilarProducts={goToSimilarProducts}
                options={options}
                productId={productId}
                refType={refType}
                showAddToBag={!isSoldOut}
                showSeeSimilar={!isBeautyProduct}
              />
            )}
            {is3dImageAvailable && (
              <div className="three-d-label">
                <Badge3D />
              </div>
            )}
            {!!premiumTags && (
              <div
                className={
                  isComingSoon ? 'premium-label-coming-soon' : 'premium-label'
                }
                data-cy={cy ? `${cy}__premium_label` : undefined}
              >
                <span className={Typo.overline}>{premiumTags}</span>
              </div>
            )}
          </div>
        )}
      </figure>
    </a>
  )
}

ProductItemFigure.defaultProps = {
  className: '',
  coverSrc: '',
  cy: undefined,
  discountBadge: '',
  displayHoverInteractions: false,
  displayPremiumTags: true,
  hoverSrc: '',
  is3dImageAvailable: false,
  isBackInStock: false,
  isBeautyProduct: false,
  isListView: false,
  isLowStock: false,
  isSoldOut: false,
  isComingSoon: false,
  comingSoonText: '',
  name: '',
  onClick: undefined,
  options: null,
  productUrl: '',
  refType: null,
  stockQuantity: 0,
}

ProductItemFigure.propTypes = {
  className: PropTypes.string,
  coverSrc: PropTypes.string,
  cy: PropTypes.string,
  discountBadge: PropTypes.string,
  displayHoverInteractions: PropTypes.bool,
  displayPremiumTags: PropTypes.bool,
  goToSimilarProducts: PropTypes.func.isRequired,
  hoverSrc: PropTypes.string,
  isBackInStock: PropTypes.bool,
  is3dImageAvailable: PropTypes.bool,
  isBeautyProduct: PropTypes.bool,
  isListView: PropTypes.bool,
  isLowStock: PropTypes.bool,
  isSoldOut: PropTypes.bool,
  isComingSoon: PropTypes.bool,
  comingSoonText: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  productId: PropTypes.number.isRequired,
  productUrl: PropTypes.string,
  stockQuantity: PropTypes.number,
  refType: PropTypes.string,
  t: PropTypes.func.isRequired,
}

export { ProductItemFigure }

const Extended = withI18next()(ProductItemFigure)

export default Extended
