import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import cartItemDuck from 'components/cart/cart-items/duck'
import SnackBar from 'components/common/snack-bar'
import Button from 'components/common/button'
import Icon from 'components/common/icon'
import ICONS, { ICON_SIZE } from 'components/common/icon/const'
import { BUTTON_TYPES } from 'constants/button'
import Typo from 'constants/typography'
import { withI18next } from 'lib/i18n/withI18next'
import { hasLocizeTranslation } from 'lib/utils/locize'
import { getShopInfo } from 'lib/utils/shop-lang'
import CartDiscount from '../cart-discount'
import CartEmpty from '../cart-empty'
import CartItems from '../cart-items'
import CartJustForYou from '../cart-just-for-you'
import CartRecommendation from '../cart-recommendation'
import CartSummary from '../cart-summary'
import style from './style.scss'

const CartMainBody = ({
  apiError,
  cartData,
  cartJustOpened,
  cartProducts,
  cartQuantity,
  country,
  goToCheckout,
  oneSizeABVariation,
  openFreeGift,
  setApiError,
  showFallbackModal,
  showHoolahSuggestion,
  showLoyaltyBenefit,
  t,
  updateVoucherErrorMessage,
  updateIsFreeGiftAdded,
}) => {
  const [errorMessage, setErrorMessage] = useState()
  const [snackbarMessageTime, setSnackbarMessageTime] = useState(0)
  const [isAddRecommended, setIsAddRecommended] = useState()
  const [isFreeGiftAdded, setIsFreeGiftAdded] = useState()

  const isCartEmpty = !cartQuantity && !cartProducts?.items?.length
  const hasSoldOut = cartData?.hasSoldOutItem
  const hasFreeGiftInCart = (cartProducts?.items || []).some(
    (product) => product.type === 'gift',
  )

  const isOneSize = oneSizeABVariation === 'b'
  const className = clsx('cart-body', {
    'has-message': cartProducts?.summary?.voucher_message,
    'cart-body-center': !isOneSize && isCartEmpty,
  })
  const currentShop = getShopInfo({ key: 'slug', value: country })

  const handleCloseApiError = () => setApiError('')
  const handleCloseError = () => setErrorMessage('')
  const handleCloseAddRecommended = () => setIsAddRecommended(false)
  const handleCloseFreeGift = () => setIsFreeGiftAdded(false)

  useEffect(() => {
    if (snackbarMessageTime !== cartData?.snackbarMessage?.time) {
      setSnackbarMessageTime(cartData.snackbarMessage.time)
      setIsAddRecommended(true)
    }
    if (cartData?.voucherErrorMessage) {
      setErrorMessage(cartData.voucherErrorMessage)
      updateVoucherErrorMessage('')
    }
    if (cartData?.isFreeGiftAdded) {
      setIsFreeGiftAdded(cartData.isFreeGiftAdded && hasFreeGiftInCart)
      updateIsFreeGiftAdded(false)
    }
  }, [
    snackbarMessageTime,
    cartData,
    updateVoucherErrorMessage,
    updateIsFreeGiftAdded,
    hasFreeGiftInCart,
  ])

  return (
    <React.Fragment>
      <style jsx>{style}</style>
      <div className={className}>
        {isCartEmpty ? (
          <CartEmpty />
        ) : (
          <React.Fragment>
            {cartProducts.eligibility?.free_gift && !hasFreeGiftInCart && (
              <div
                className="free-gifts left-text-area"
                onClick={openFreeGift}
                onKeyDown={openFreeGift}
                role="button"
                tabIndex={0}
              >
                <Icon size={ICON_SIZE.large} src={ICONS.freeGift} />
                <div className="free-gifts__info">
                  <span className={Typo.subtitle2}>
                    {hasLocizeTranslation(
                      t,
                      'CART_FREE_GIFT_LABEL',
                      'Free Gift Available',
                    )}
                  </span>
                  <span className={clsx(Typo.caption, 'free-gifts__info-sub')}>
                    {hasLocizeTranslation(
                      t,
                      'CART_FREE_GIFT_INFO',
                      'Hurry, Check it out!',
                    )}
                  </span>
                </div>
                <Icon
                  size={ICON_SIZE.small}
                  src={ICONS.arrowHeadRight}
                  className="free-gifts__btn"
                />
              </div>
            )}
            <CartItems
              allowProductUpdate
              setError={setApiError}
              showFallbackModal={showFallbackModal}
            />
            <CartJustForYou />
            <CartDiscount
              cartProducts={cartProducts}
              fromSummary={false}
              setError={setErrorMessage}
            />
            <CartSummary
              priceSummary={cartProducts?.summary}
              showLoyaltyBenefit={showLoyaltyBenefit}
              showHoolahSuggestion={showHoolahSuggestion}
              showTitle
              t={t}
            />
          </React.Fragment>
        )}
        {isOneSize && <CartRecommendation cartJustOpened={cartJustOpened} />}
      </div>
      {/* Help snack bar floating at bottom */}
      <div className="cart-snackbar" />
      {/* TODO: Refactor snackbar on cart components */}
      <div className={clsx(Typo.caption, 'cart-snackbar__overlay')}>
        <SnackBar
          className="cart-body__snackbar"
          open={currentShop.id_shop === 5 && cartQuantity === 20}
        >
          <div className="cart-max-quantity-s2s">
            {hasLocizeTranslation(
              t,
              'CART_ITEM_INDO_LIMIT_WARNING_MESSAGE',
              'Oops! You have already added the maximum number of items in your bag. Please remove some items to make space.',
            )}
          </div>
        </SnackBar>
        <SnackBar
          className="cart-body__snackbar"
          open={isFreeGiftAdded}
          duration={8000}
          onClose={handleCloseFreeGift}
        >
          <span>
            {t("Your free gift depends on your cart's total after discount.")}{' '}
            {t(
              'Removing items from your cart may also remove the free gift you selected.',
            )}
          </span>
        </SnackBar>
        <SnackBar
          className="cart-body__snackbar"
          open={!!apiError}
          onClose={handleCloseApiError}
          duration={10000}
        >
          <span>{apiError}</span>
        </SnackBar>
        <SnackBar
          className="cart-body__snackbar"
          open={!!cartProducts?.shipping_message}
        >
          <span>{cartProducts?.shipping_message}</span>
        </SnackBar>
        <SnackBar
          className="cart-body__snackbar"
          open={!!errorMessage}
          onClose={handleCloseError}
          duration={5000}
        >
          <span>{errorMessage}</span>
        </SnackBar>
        <SnackBar
          className="cart-body__snackbar"
          open={isAddRecommended && cartData?.snackbarMessage?.text}
          onClose={handleCloseAddRecommended}
          duration={5000}
        >
          <span>{t(cartData?.snackbarMessage?.text)}</span>
        </SnackBar>
        <SnackBar className="cart-body__snackbar" open={hasSoldOut}>
          <span>
            {hasLocizeTranslation(
              t,
              'SOLD_OUT_CART_SNACKBAR',
              'Oops. An item in your bag is SOLD OUT. Please remove it from your bag.',
            )}
          </span>
        </SnackBar>
      </div>
      {!isCartEmpty && (
        <div className="cart-checkout-wrapper">
          <Button
            className="cart-checkout-button"
            cy="cart__checkout"
            disabled={hasSoldOut}
            onClick={goToCheckout}
            type={BUTTON_TYPES.primary}
          >
            {hasSoldOut
              ? hasLocizeTranslation(
                  t,
                  'CART_CTA_REMOVE_ITEM',
                  'Remove Sold Out Item',
                )
              : hasLocizeTranslation(
                  t,
                  'CART_CTA_CHECKOUT',
                  'proceed to Checkout',
                )}
          </Button>
        </div>
      )}
    </React.Fragment>
  )
}

CartMainBody.defaultProps = {
  apiError: undefined,
  cartData: null,
  cartProducts: null,
  cartQuantity: undefined,
  oneSizeABVariation: null,
  setApiError: undefined,
}

CartMainBody.propTypes = {
  apiError: PropTypes.string,
  cartData: PropTypes.shape({
    snackbarMessage: PropTypes.shape({
      text: PropTypes.string,
      time: PropTypes.number,
    }),
    hasSoldOutItem: PropTypes.bool,
    voucherErrorMessage: PropTypes.string,
    isFreeGiftAdded: PropTypes.bool,
  }),
  cartJustOpened: PropTypes.bool.isRequired,
  cartProducts: PropTypes.shape({
    eligibility: PropTypes.shape({
      free_gift: PropTypes.shape({}),
    }),
    shipping_message: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({})),
    summary: PropTypes.shape({
      voucher_message: PropTypes.string,
    }),
  }),
  cartQuantity: PropTypes.number,
  country: PropTypes.string.isRequired,
  goToCheckout: PropTypes.func.isRequired,
  oneSizeABVariation: PropTypes.string,
  openFreeGift: PropTypes.func.isRequired,
  setApiError: PropTypes.func,
  showFallbackModal: PropTypes.func.isRequired,
  showHoolahSuggestion: PropTypes.bool.isRequired,
  showLoyaltyBenefit: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  updateVoucherErrorMessage: PropTypes.func.isRequired,
  updateIsFreeGiftAdded: PropTypes.func.isRequired,
}

const Extended = withI18next()(CartMainBody)

export default connect(
  () => ({}),
  (dispatch) =>
    bindActionCreators(
      {
        updateVoucherErrorMessage:
          cartItemDuck.creators.updateVoucherErrorMessage,
        updateIsFreeGiftAdded: cartItemDuck.creators.updateIsFreeGiftAdded,
      },
      dispatch,
    ),
)(Extended)
